/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ReportReason = 'UncomfortableCommunication' | 'Canceling' | 'Rescheduling' | 'CancelingOnTheDay' | 'CancelingWithoutNotice' | 'OtherServiceSolicitation' | 'CompetingContractor' | 'BusinessSolicitation' | 'FraudulentAct' | 'ProfileImageFraud' | 'VileBehavior' | 'AbusiveLanguage' | 'Other' | 'Appointment_Canceling' | 'Appointment_ReschedulingOnTheDay' | 'Appointment_NoContactOnTheDay' | 'Appointment_Late' | 'Appointment_ReschedulingForLateArrival' | 'Appointment_CanceledForLateArrival' | 'Appointment_OtherServiceSolicitation' | 'Appointment_InvestmentSolicitation' | 'Appointment_SolicitationForSexWork' | 'Appointment_SolicitationForMaleHostClub' | 'Appointment_SolicitationForOtherService' | 'Appointment_FakeProfileImage' | 'Appointment_ProfileImageFraud' | 'Appointment_UseOfSomeoneElsesPhoto' | 'Appointment_Theft' | 'Appointment_Other';

export const ReportReason = {
    UncomfortableCommunication: 'UncomfortableCommunication' as ReportReason,
    Canceling: 'Canceling' as ReportReason,
    Rescheduling: 'Rescheduling' as ReportReason,
    CancelingOnTheDay: 'CancelingOnTheDay' as ReportReason,
    CancelingWithoutNotice: 'CancelingWithoutNotice' as ReportReason,
    OtherServiceSolicitation: 'OtherServiceSolicitation' as ReportReason,
    CompetingContractor: 'CompetingContractor' as ReportReason,
    BusinessSolicitation: 'BusinessSolicitation' as ReportReason,
    FraudulentAct: 'FraudulentAct' as ReportReason,
    ProfileImageFraud: 'ProfileImageFraud' as ReportReason,
    VileBehavior: 'VileBehavior' as ReportReason,
    AbusiveLanguage: 'AbusiveLanguage' as ReportReason,
    Other: 'Other' as ReportReason,
    AppointmentCanceling: 'Appointment_Canceling' as ReportReason,
    AppointmentReschedulingOnTheDay: 'Appointment_ReschedulingOnTheDay' as ReportReason,
    AppointmentNoContactOnTheDay: 'Appointment_NoContactOnTheDay' as ReportReason,
    AppointmentLate: 'Appointment_Late' as ReportReason,
    AppointmentReschedulingForLateArrival: 'Appointment_ReschedulingForLateArrival' as ReportReason,
    AppointmentCanceledForLateArrival: 'Appointment_CanceledForLateArrival' as ReportReason,
    AppointmentOtherServiceSolicitation: 'Appointment_OtherServiceSolicitation' as ReportReason,
    AppointmentInvestmentSolicitation: 'Appointment_InvestmentSolicitation' as ReportReason,
    AppointmentSolicitationForSexWork: 'Appointment_SolicitationForSexWork' as ReportReason,
    AppointmentSolicitationForMaleHostClub: 'Appointment_SolicitationForMaleHostClub' as ReportReason,
    AppointmentSolicitationForOtherService: 'Appointment_SolicitationForOtherService' as ReportReason,
    AppointmentFakeProfileImage: 'Appointment_FakeProfileImage' as ReportReason,
    AppointmentProfileImageFraud: 'Appointment_ProfileImageFraud' as ReportReason,
    AppointmentUseOfSomeoneElsesPhoto: 'Appointment_UseOfSomeoneElsesPhoto' as ReportReason,
    AppointmentTheft: 'Appointment_Theft' as ReportReason,
    AppointmentOther: 'Appointment_Other' as ReportReason
};

